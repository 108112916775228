import { useEffect, useState } from 'react';
import { connectScreenCloud } from '@screencloud/apps-sdk';

function App() {
  const [appStarted, setAppStarted] = useState(false);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    async function start() {
      const isTest = window.location.search == '?test=true'

      const testData = {
        config: {
          refreshTimeSeconds: 7,
          wallUrl: 'https://display.taggbox.com/music-festival',
          themeId: 58,
          num_of_posts_58: 30
        }
      };

      const sc = isTest ? await connectScreenCloud(testData) : await connectScreenCloud();
      const appConfig = sc.getConfig();
      const displayUrl = appConfig.wallUrl;
      const themeId = appConfig.themeId;
      const postsCount = appConfig[`num_of_posts_${appConfig.themeId}`];
      console.log('app config is ', appConfig);
      if (displayUrl) {
        if (postsCount) {
          setUrl(`${displayUrl}?themeId=${themeId}&tb_posts_count=${postsCount}`);
        } else {
          setUrl(`${displayUrl}?themeId=${themeId}`);
        }
      }
      sc.onAppStarted().then(() => {
        setAppStarted(true);
      });
      setTimeout(() => {
        if (!appStarted) {
          setAppStarted(true)
        }
      }, 7000);
    }
    start();
  }, []);
  return appStarted ? <iframe title="Taggbox display app" src={url} width="100%" height="100%" frameBorder="0"></iframe> : null;
};


export default App;